import React, { Component } from 'react';
import 'firebase/database';
import firebase from 'firebase/app';
import numeral from 'numeral';
import { Layout, Row, Col, Card, Divider } from 'antd';
import './App.css';

const { Header, Footer, Sider, Content } = Layout;

// Initialize Firebase
var config = {
	apiKey: 'AIzaSyCDMOupLayHyt8gfhYGyES7YBRUTQ5r6J8',
	authDomain: 'softech-ioffice.firebaseapp.com',
	databaseURL: 'https://softech-iot.firebaseio.com',
	projectId: 'softech-iot',
	storageBucket: 'softech-iot.appspot.com',
	messagingSenderId: '308286403226',
};
firebase.initializeApp(config);

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			humidity: 0,
			temperature: 0,
		};
	}

	async getMessages() {
		this.ref = firebase.database().ref('iot/locations/softech');
		this.ref.on('value', async (snapshot) => {
			var value = snapshot.val();

			if (value != null) {
				this.setState({ humidity: value.humidity, temperature: value.temperature });
				this.setState({ loading: false });
			}
		});
	}

	componentWillUnmount() {
		this.ref = null;
	}

	async componentDidMount() {
		this.getMessages();
	}

	render() {
		return (
			<div style={{ height: '100vh', display: 'flex', flex: 1, backgroundColor: '#ECECEC' }}>
				<Content style={{ padding: 8 }}>
					<Card loading={this.state.loading} title='Softech Building' bordered={true} style={{ width: '100%' }}>
						<Row gutter={24}>
							<Col span={16}>
								<span style={{ fontWeight: 700 }}>Humidity (%):</span>
							</Col>
							<Col style={{ textAlign: 'right' }}>
								<span style={{ fontWeight: 700 }}>{numeral(this.state.humidity).format('00.00')}</span>
							</Col>
						</Row>
						<div style={{ height: 16 }} />
						<Row gutter={24}>
							<Col span={16}>
								<span style={{ fontWeight: 700 }}>Temperature (°C):</span>
							</Col>
							<Col style={{ textAlign: 'right' }}>
								<span style={{ fontWeight: 700 }}>{numeral(this.state.temperature).format('00.00')}</span>
							</Col>
						</Row>
					</Card>
				</Content>
			</div>
		);
	}
}

export default App;
